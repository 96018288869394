








































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectDocumentsActions, ProjectDocumentsMutations } from '@store/modules/project-documents/Types';
import { IProject, IProjectState } from '@store/modules/project/Interfaces';
import { IDocument, IDocumentsState } from '@store/modules/project-documents/Interfaces';
import { makeBlur } from '@utility/makeBlur';

import './scss/TheDocuments.scss';
import { ProjectActions } from '@store/modules/project/Types';
import { IUser, IUserState } from '@store/modules/user/Interfaces';
import { ITask } from '@store/modules/project-tasks/interfaces/Interfaces';
import { ProjectTasksActions, ProjectTasksMutations } from '@store/modules/project-tasks/Types';
import { IAddTaskPostData } from '@store/modules/project-tasks/interfaces/ApiRequests';
import { IWork, IWorkListState } from '@store/modules/work-list/interfaces/Interfaces';
import { formatDate } from '@utility/formatDate';
import { WorkListActions, WorkListMutations } from '@store/modules/work-list/Types';
import { UserActionTypes } from '@store/modules/user/Types';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');
const NSTasks = namespace('storeProjectTasks');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'TheDocuments',
    components: {
        BasePreloader: () => import('@components/BasePreloader'),
        DocumentsGroup: () => import('./components/DocumentsGroup.vue'),
        DocumentsComposite: () => import('./components/DocumentsComposite.vue'),
        DocumentsActions: () => import('./components/DocumentsActions.vue'),
        DocumentsPopups: () => import('@pages/the-documents/components/DocumentsPopups.vue'),
        ExecutiveDocsComposite: () => import('@pages/the-documents/components/ExecutiveDocsComposite.vue'),
    }
})

export default class TheDocuments extends Vue {
    $refs!: {
        documentsPopups: any,
    }
    @NSTasks.Getter('tasks') tasks!: ITask[];
    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.Action(ProjectActions.A_GET_PROJECT) reloadDocuments!: (any) => Promise<void>
    @NSProject.State((state: IProjectState) => state.projectLoaded) projectLoaded!: boolean;
    @NSTasks.Action(ProjectTasksActions.A_GET_PROJECT_TASKS) getProjectTasks!: (projectId: number) => Promise<void>;
    @NSTasks.Action(ProjectTasksActions.A_GET_PROJECT_TASKS) setProjectTasks!: (projectId: string | number) => Promise<void>;
    @NSTasks.Action(ProjectTasksActions.A_CREATE_NEW_TASK)
        sendAddTaskFormAction!: ({ postData: IAddTaskPostData }) => Promise<any>
    @NSTasks.Mutation(ProjectTasksMutations.M_SET_PROJECT_TASKS_LOADED) setProjectTasksStore!: (loadedState: boolean) => void;

    @NSDocuments.Action(ProjectDocumentsActions.A_SET_UNREAD_DOCUMENTS) setUnreadDocuments!: (payload: string[]) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_SET_VIEW_MODE) setViewMode!: (payload: string) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_CATEGORY_LIST) getCategoryList!: (projectId: number | string) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOCUMENT_STATUSES) getDocsStatuses!: (id: number) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_PACK_PD_OPTIONS) getPackPDList!: (id: number) => Promise<void>;

    @NSDocuments.Getter('viewMode') viewMode!: string;
    @NSDocuments.Getter('packPDList') packPDList!: any;
    @NSDocuments.Getter('documents') documents!: IDocument[];
    @NSDocuments.Getter('compositeShow') compositeShow!: boolean;
    @NSDocuments.Getter('categoriesForSelect') categoriesForSelectStore!: string[];

    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_COMPOSITE_SHOW) setCompositeShow!: (isShow: boolean) => void;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_PROJECT_DOCUMENTS_LOADED) setDocumentsLoaded!: (loadedState: boolean) => void;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_VIEWING_DOCUMENT) setViewingDocument!: (document: IDocument | null) => void;

    @NSDocuments.Getter('unreadDocuments') documentsUnread!: string[];
    @NSDocuments.State((state: IDocumentsState) => state.viewingDocument) viewingDocument!: IDocument;
    @NSDocuments.State((state: IDocumentsState) => state.projectDocumentsLoaded) projectDocumentsLoaded!: boolean;
    @NSDocuments.Getter('filters') filters!: any[];
    @NSDocuments.Getter('filtersApply') filtersApply!: boolean;

    @NSUser.Getter('userData') userData!: IUserState;
    @NSUser.Getter('isAdminOnCurrentProject') isAdminOnCurrentProject!: boolean;
    @NSUser.Action(UserActionTypes.A_REFRESH_TOKEN) refreshToken!: () => Promise<IUser>
    @NSDocuments.Getter('selectedDocuments') selectedDocumentsStore!: string[];
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_SELECTED_DOCUMENTS) setSelectedDocuments!: (payload: string[]) => void;
    @NSWorkList.State((state: IWorkListState) => state.viewingWork) viewingWork!: IWork;
    @NSWorkList.State((state: IWorkListState) => state.viewingPackExecutive) viewingPackExecutive!: any;
    @NSWorkList.Action(WorkListActions.A_GET_WORK_LIST) getWorkList!: (projectId: number) => Promise<void>;
    @NSWorkList.Mutation(WorkListMutations.M_SET_WORK_LIST_LOADED) setWorkListLoaded!: (loadedState: boolean) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOCUMENTS) getDocuments!: (appId: number) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_SET_PROJECT_DOCUMENTS) setDocumentsInStore!: (docs: IDocument[]) => Promise<void>
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOC_FILES) getDocFiles!:
        (payload: { appId: number, documentId: string }) => Promise<any>;
    @NSDocuments.Action(ProjectDocumentsActions.A_DOWNLOAD_FILES_ZIP) downloadFilesZip!: (payload: {
        data: {
            appId: string | number;
            ids: string;
        },
        nameArchive: string,
    }) => Promise<void>;

    @NSDocuments.Action(ProjectDocumentsActions.A_CHANGE_DOCUMENT_STATUS)
    changeDocumentStatus!: (payload: {
        projectId: number;
        documentId: string[];
        statusId: number;
    }) => void;

    selectedDocuments: IDocument[] = [];
    textConstants = {all: 'Все'}
    selectedDocumentationType: string = '';
    closePopups: boolean = false;
    selectedTaskToView = {} as ITask;
    timeForReloadSubtasksList: number = 500;

    mounted() {
        this.onMounted(true);
    }

    @Watch('$route.params.projectId')
    watchProjectId() {
        this.setDocumentsLoaded(false);
    }

    @Watch('projectDocumentsLoaded')
    watchProjectLoaded() {
        if (!this.projectDocumentsLoaded) {
            this.setViewMode(this.viewMode);
        }
    }

    @Watch('projectLoaded')
    onProjectLoaded(): void {
        if (this.projectLoaded) {
            this.requestsAfterMainDataLoaded();
        }
    }

    get viewModeExecutive(): boolean {
        return this.viewMode === 'executiveDocs';
    }

    get nameArchive() {
        let dateNow = formatDate({date: new Date(), format: 'dd_mm_yyyy'});
        return `Documentation_archive_${dateNow}.zip`;
    }

    get unreadDocuments(): IDocument[] {
        let output: IDocument[] = [];
        if (this.documentsUnread && this.documentsUnread.length) {
            this.documentsUnread.forEach((unreadDocId) => {
                let documents: IDocument[] = this.documents;
                if (this.viewingDocument) {
                    documents = this.viewingDocument.children;
                }
                for (const doc of documents) {
                    if (doc.uuid === unreadDocId) {
                        output.push(doc);
                    }
                }
            });
        }
        return output;
    }

    get categoriesForSelect() {
        return [this.textConstants.all].concat(this.categoriesForSelectStore);
    }

    get documentGroups() {
        let allReadDocuments: IDocument[] = [];
        const packEDAndDocsForWork: any[] = [];
        if (this.viewMode === 'executiveDocs' && this.compositeShow && this.viewingWork && this.viewingWork.constructs) {
            this.viewingWork.constructs.forEach(construct => {
                const docsIds = construct.documents.map(doc => doc.uuid)
                packEDAndDocsForWork.push({
                    construct: construct.uuid,
                    constructTitle: construct.title,
                    docs: docsIds,
                });
            });
        }

        if (this.viewMode === 'executiveDocs' && !this.compositeShow && this.viewingPackExecutive.length && this.packPDList) {
            const packPDItem = this.packPDList.filter(packItem => packItem.title === this.viewingPackExecutive)[0];
            if (packPDItem && packPDItem.packED) {
                packPDItem.packED.forEach(item => {
                    packEDAndDocsForWork.push({
                        construct: item.uuid,
                        constructTitle: item.title,
                        agreementProcess: item.agreementProcess,
                        pddTask: item.pddTask,
                        docs: item.documentsIds,
                    });
                });
            }
        }
        let documents = this.documents.filter(
            doc => doc.author?.uuid,
            doc => doc.status,
        );

        if (this.viewingDocument) {
            this.documents.forEach((item) => {
                if (item.uuid === this.viewingDocument.uuid) {
                    this.setViewingDocument(item);
                }
            })
            documents = this.viewingDocument.children;
        }

        documents.forEach((item) => {
            if (this.viewMode === 'executiveDocs') {
                allReadDocuments.push(item);
            } else if (this.documentsUnread.indexOf(item.uuid) === -1) {
                allReadDocuments.push(item);
            }
        });

        if (this.filtersApply) {
            this.filters.forEach((filterObject) => {
                if (filterObject.type === 'select') {
                    if (filterObject.key === 'author.fullName') {
                        allReadDocuments = allReadDocuments.filter(
                            doc => filterObject.selected.includes(doc.author.fullName),
                            doc => !doc.isFolder,
                        );
                    }
                    if (filterObject.key === 'status.title') {
                        allReadDocuments = allReadDocuments.filter(
                            doc => doc.status ? filterObject.selected.includes(doc.status.title) : false,
                            doc => !doc.isFolder,
                        );
                    }
                }
                if (filterObject.type === 'dateRange' && (filterObject.datesRange.dateFrom || filterObject.datesRange.dateTo)) {
                    allReadDocuments = allReadDocuments.filter(
                        doc => filterObject.datesRange.dateFrom ? Date.parse(doc[filterObject.key]) >= Date.parse(filterObject.datesRange.dateFrom) : true,
                        doc => !doc.isFolder,
                    );
                    allReadDocuments = allReadDocuments.filter(
                        doc => filterObject.datesRange.dateTo ? Date.parse(doc[filterObject.key]) <= Date.parse(filterObject.datesRange.dateTo) : true,
                        doc => !doc.isFolder,
                    );
                }
            });
        }

        if ((this.viewMode === 'executiveDocs' && this.compositeShow && this.viewingWork)
            || (this.viewMode === 'executiveDocs' && !this.compositeShow && this.viewingPackExecutive.length)) {
            const output: any[] = [];
            packEDAndDocsForWork.forEach((category) => {
                const filteredDocs = allReadDocuments.filter(
                    doc => category.docs.includes(doc.uuid),
                    doc => !doc.isFolder,
                );
                if (category.constructTitle
                    && category.constructTitle.length
                    && filteredDocs.length
                ) {
                    output.push({
                        constructId: category.construct,
                        category: category.constructTitle,
                        documents: filteredDocs,
                        agreementProcess: category.agreementProcess || false,
                        pddTask: category.pddTask || false,
                    });
                }
            });
            return output;
        }

        if (this.viewMode === 'executiveDocs' && !this.compositeShow && !this.viewingPackExecutive.length) {
            return [];
        }

        return this.documentsByGroups(this.categoriesForSelect, allReadDocuments);
    }

    get newTaskDetailsSliderInfo(): ITask {
        const selectedTaskId: string = this.selectedTaskToView.uuid;
        return this.tasks.filter((task) => task.uuid === selectedTaskId)[0];
    }

    onMounted(tryRefreshToken = false) {
        this.getWorkList(parseInt(this.$route.params.projectId, 10))
            .catch((err) => {
                if (tryRefreshToken && err.response?.status === 401) {
                    this.refreshToken().then(() => {
                        this.onMounted();
                    });
                }
            })
            .finally(() => {
            this.setWorkListLoaded(true);
        });
        this.$nextTick(() => {
            if (localStorage.getItem('routFrom') !== 'projects') {
                this.reloadDocumentsMethod();
                this.onProjectLoaded();
            }
        })
    }

    requestsAfterMainDataLoaded() {
        this.getCategoryList(this.projectId);
        this.getDocsStatuses(this.projectId);
        this.getPackPDList(this.projectId);
    }

    openDocumentsList(params): void {
        this.callPopupsMethod('openDocumentsList', params);
        this.setUnreadDocuments(this.documentsUnread.filter((unreadDocumentId: string) => (
            unreadDocumentId !== params.docData.uuid
        )));
    }

    documentsByGroups(groups, allReadDocuments) {
        const output: any[] = [];
        groups.forEach((category) => {
            const filteredDocs = allReadDocuments.filter(
                doc => doc.category === category || doc.type === category,
                doc => !doc.isFolder,
            );
            if (
                filteredDocs.length &&
                (
                    !this.selectedDocumentationType ||
                    (
                        this.selectedDocumentationType === category || this.selectedDocumentationType === this.textConstants.all
                    )
                )
            ) {
                output.push({
                    category,
                    documents: filteredDocs,
                })
            }
        });
        return output;
    }

    setViewModeMethod(value) {
        this.setViewMode(value);
        this.setSelectedDocuments([]);
    }

    ifAuthor(doc) {
        return doc.author && doc.author.uuid && doc.author.uuid === this.userData.userInfo.id;
    }

    callPopupsMethod(method, params: any = null) {
        this.$refs.documentsPopups[method](params);
    }

    compositeToggle() {
        this.setCompositeShow(!this.compositeShow);
        makeBlur();
    }

    reloadDocumentsMethod() {
        this.setDocumentsLoaded(false);
        this.getDocuments(parseInt(this.$route.params.projectId, 10)).then(
            (data: any) => {
                this.setDocumentsInStore(data).then(() => {
                    this.setDocumentsLoaded(true);
                });
            }
        );
    }

    reloadPackPDListMethod() {
        this.setDocumentsLoaded(false);
        this.getPackPDList(parseInt(this.$route.params.projectId, 10)).then(
            (_) => {
                this.setDocumentsLoaded(true);
            }
        );
    }

    downloadGroup(e) {
        this.downloadFilesZip({
            data: {
                appId: this.projectId,
                ids: e.documents.map(doc => doc.uuid).join(','),
            },
            nameArchive: this.nameArchive,
        });
    }

    reloadProjectMethod() {
        this.setUnreadDocuments([]);
        this.setDocumentsLoaded(false);
        setTimeout(() => {
            this.reloadDocuments({projectId: this.projectData.id, reloadDocuments: true})
                .then(() => {
                    this.setDocumentsLoaded(true);
                });
        }, 1);
    }

    successNotificationPopupShow(title: string, text: string, timeForReading: number | undefined = undefined) {
        this.$refs.documentsPopups.successNotificationPopupShow(title, text, timeForReading);
    }

    signSuccessNotify() {
        this.successNotificationPopupShow(
            'Файлы подписаны',
            'Все файлы выбранного документа были успешно подписаны.',
        )
    }

    addDocumentSuccessNotify() {
        this.successNotificationPopupShow(
            'Документ добавлен',
            'Новый документ был успешно добавлен',
        )
        this.reloadDocumentsMethod();
    }

    editDocumentSuccessNotify(type): void {
        this.successNotificationPopupShow(
            'Документ отредактирован',
            'Выбранный документ успешно отредактирован',
        );
        if (type === 'upload') {
            this.reloadDocumentsMethod();
        }
    }

    replaceDocumentSuccessNotify() {
        this.successNotificationPopupShow(
            'Документ перемещен',
            'Выбранный документ успешно перемещен',
        )
        this.reloadDocumentsMethod();
    }

    confirmAddCompositeFolder(nameFolder) {
        this.successNotificationPopupShow('Добавление группы', `Группа "${nameFolder}" добавлена`);
        this.reloadDocumentsMethod();
    }

    reloadStoreTasks() {
        this.getProjectTasks(this.projectId).then(() => {
            this.setProjectTasksStore(true);
        });
    }

    confirmDownloadDocuments(data) {
        let downloadingNotificationText = '';
        if (data.loading) {
            if (data.countDocuments === 1) {
                downloadingNotificationText = `Документ "${data.nameDocument}" загружается`;
            } else {
                downloadingNotificationText = `Документы загружаются: ${data.countDocuments} шт.`;
            }

        } else {
            if (data.countDocuments === 1) {
                downloadingNotificationText = `Документ "${data.nameDocument}" загружен`;
            } else {
                downloadingNotificationText = `Документы загружены: ${data.countDocuments} шт.`;
            }
        }
        if (data.errorDownload) {
            downloadingNotificationText = 'Ошибка при загрузке';
        }
        this.successNotificationPopupShow('Загрузка', downloadingNotificationText);
    }

    openPopupDocsStatus() {
        const documentsById: IDocument[] = this.documents.filter(item => this.selectedDocumentsStore.includes(item.uuid));
        const statuses: any[] = [];
        documentsById.forEach(item => {
            if (item.status && item.status.id && !statuses.includes(item.status.id)) {
                statuses.push(item.status.id);
            }
        });
        if (statuses.length > 1) {
            this.successNotificationPopupShow(
                'Внимание',
                'Выбранные вами документы имеют разные статусы. Для смены статуса у набора документов все выбранные документы должны иметь одинаковый статус.',
                5000,
            )
        } else {
            this.callPopupsMethod('openPopupSetDocsStatus', documentsById);
        }
    }

    openPopupDocsList(data: any = null) {
        const selectedDocs = data ? data.docs : this.documents.filter(item => this.selectedDocumentsStore.includes(item.uuid));
        let selectedDocsGetFilesPromises = selectedDocs.map((doc) => {
            return this.getDocFiles({
                appId: this.projectId,
                documentId: doc.uuid,
            })
            .then((files) => {
                doc.files = files;
            });
        });

        if (data && data.signData) {
            this.callPopupsMethod(
                'setPackPDSignData',
                {
                    id: data.id,
                    fileData: data.signData,
                    filesIds: data.filesIds,
                }
            );
        }

        Promise.all(selectedDocsGetFilesPromises)
            .then(() => {
                this.callPopupsMethod(
                    'openDocumentsSignature',
                    selectedDocs
                );
            });
    }

    checkUserRights(task: ITask): boolean {
        let accessAllowed: boolean = false;
        const userId: string = this.userData?.userInfo?.id;
        if (this.isAdminOnCurrentProject || task.author?.uuid === userId || task.executor?.uuid === userId) {
            accessAllowed = true;
        }
        return accessAllowed;
    }

    addSubtask(taskFormData: IAddTaskPostData): void {
        this.sendAddTaskFormAction({ postData: taskFormData }).then(() => {
            setTimeout(() => {
                this.$refs.documentsPopups.updateTaskDetailsSlider();
            }, this.timeForReloadSubtasksList + 100);
            this.successNotificationPopupShow('Добавление подзадачи', 'Подзадача добавлена');
        });
    }
}
